import React, { useEffect, useState } from 'react'
import DropDown from '../../../components/drop-down/DropDown'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import SearchIcon from '@material-ui/icons/Search'
import Typography from '@material-ui/core/Typography'
import DonutLargeIcon from '@material-ui/icons/DonutLarge'
import image1 from '../../../assets/images/image1.svg'
import LinearProgress from '@material-ui/core/LinearProgress'
import Swal from 'sweetalert2'
import MenuIcon from '@material-ui/icons/Menu'
import sty from './Questions.module.css'
import ScrollReveal from 'scrollreveal'

/*Styles*/
const useStyles = makeStyles({
	root: {
		position: 'relative',
		width: '100vw',
	},
	topBar: {
		position: "relative",
		width: '85%',
		height: 60,
		left: 0,
		right: 0,
		margin: 'auto',
		marginTop: 20,
		borderRadius: 50,
		backgroundColor: '#ffffff',
		boxShadow: `4px 8px 25px 4px #e6e6e6`
	},
	homeTitle: {
		position: "absolute",
		fontFamily: `Gurulugomi`,
		fontSize: 50,
		left: 100,
		top: '55%',
		transform: `translateY(-50%)`,
		'@media all and (max-width: 820px)': {
			left: 30,
			transform: `translate(0%, -50%)`,
		}
	},
	menuContainer1: {
		position: 'absolute',
		width: 'max-content',
		display: 'flex',
		gap: 20,
		left: '50%',
		top: '50%',
		transform: `translate(-50%, -50%)`,
		'@media all and (max-width: 820px)': {
			left: 200,
			transform: `translate(0%, -50%)`,
		},
		'@media all and (max-width: 690px)': {
			display: 'none',
		},
	},
	menuContainer2: {
		position: 'absolute',
		width: 'max-content',
		display: 'flex',
		gap: 20,
		right: 50,
		top: '50%',
		transform: `translateY(-50%)`,
		'@media all and (max-width: 820px)': {
			left: 340,
			transform: `translate(0%, -50%)`,
		},
		'@media all and (max-width: 690px)': {
			display: 'none',
		},
	},
	menuContainer3: {
		position: 'absolute',
		width: 'max-content',
		display: 'none',
		gap: 20,
		left: '50%',
		top: '75%',
		transform: `translate(-50%, -50%)`,
		'@media all and (max-width: 690px)': {
			display: 'none',
		}
	},
	menuItem: {
		fontFamily: `Poppins, sans-serif`,
		fontSize: 16,
		color: 'black',
		cursor: 'pointer',
		transition: `all 0.1s ease-out`,
		'&:hover': {
			color: '#448dc1',
			transition: `all 0.1s ease-in`,
		}
	},
	menuIcon: {
		position: 'absolute',
		display: 'none',
		width: 'max-content',
		cursor: 'pointer',
		right: 50,
		top: '50%',
		transform: `translateY(-50%)`,
		transition: `all 0.1s ease-out`,
		'@media all and (max-width: 690px)': {
			display: 'block'
		},
		'&:hover': {
			color: '#448dc1',
			transition: `all 0.1s ease-in`,
		}
	},
	dropDownContainer: {
		position: "relative",
		width: 750,
		display: "flex",
		flexDirection: "row",
		flexWrap: "wrap",
		columnGap: 100,
		rowGap: 30,
		justifyContent: "center",
		alignItems: "center",
		left: 0,
		right: 0,
		margin: "auto",
		marginTop: 30,
		zIndex: 2,
		'@media all and (max-width: 758px)': {
			width: 600,
			columnGap: 50,
		},
		'@media all and (max-width: 634px)': {
			width: 540,
			columnGap: 20,
		},
		'@media all and (max-width: 554px)': {
			width: 355,
		},
		'@media all and (max-width: 454px)': {
			width: 160,
			columnGap: 0,
		}
	},
	searchButton: {
		borderRadius: 100,
		width: 60,
		height: 60,
		backgroundColor: '#eae9e9',
		'&:hover': {
			backgroundColor: '#d7d4d4',
		},
		'@media all and (max-width: 554px)': {
			marginLeft: 108,
			left: '-50px'
		},
	},
	container: {
		position: 'relative',
		width: '100%',
		height: 509,
		'@media all and (max-width: 930px)': {
			height: 650
		}
	},
	questionsContainer: {
		position: 'absolute',
		width: 520,
		height: 250,
		top: 22,
		'@media all and (max-width: 554px)': {
			top: -250,
		},
		'@media all and (max-width: 454px)': {
			top: -330,
		}
	},
	questionsButton: {
		position: "absolute",
		width: 220,
		height: 40,
		top: 105,
		left: -90,
		transform: `rotate(270deg)`,
		cursor: "pointer",
		backgroundColor: '#448dc1'
	},
	questionsTitle: {
		position: "absolute",
		width: "max-content",
		left: "45%",
		top: "50%",
		transform: `translate(-50%, -50%)`,
		fontFamily: `Poppins, sans-serif`,
		fontSize: 18,
		color: "white",
		cursor: "pointer",
	},
	questionsIcon: {
		position: "absolute",
		width: "max-content",
		right: 5,
		top: "50%",
		transform: `translateY(-50%)`,
		color: "white",
	},
	questionItemParent: {
		width: 32,
		height: 32,
		borderRadius: 5,
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		backgroundColor: "#d8d8d8",
		cursor: "pointer",
		transition: `all 0.1s ease-out`,
		"&:hover": {
			backgroundColor: "#8d8a8a",
			transition: `all 0.1s ease-in`,
		},
	},
	questionItemChild: {
		fontSize: 18,
	},
	wiwaranaContainer: {
		position: "absolute",
		width: "85%",
		height: "100%",
		left: "50%",
		top: "50%",
		transform: `translate(-50%, -50%)`,
	},
	wiwarna: {
		position: "absolute",
		width: "100%",
		height: "100%",
		left: 0,
		right: 0,
		top: 0,
		bottom: 0,
		margin: "auto",
		border: "none",
	},
	circle1: {
		position: "absolute",
		zIndex: -1,
		top: 10,
		right: -80,
		width: 300,
		height: 300,
		borderRadius: "100%",
		backgroundColor: "#eaf2f7",
		'@media all and (max-width: 776px)': {
			width: 250,
			height: 250,
		},
		'@media all and (max-width: 425px)': {
			width: 200,
			height: 200
		}
	},
	triangle1: {
		position: "absolute",
		zIndex: -1,
		top: 10,
		left: 0,
		width: 0,
		height: 0,
		borderStyle: "solid",
		borderWidth: `0 100px 200px 100px`,
		borderColor: `transparent transparent #efffe7 transparent`,
		transform: `rotate(120deg)`,
		'@media all and (max-width: 776px)': {
			borderWidth: `0 80px 160px 80px`,
		},
		'@media all and (max-width: 425px)': {
			borderWidth: `0 60px 130px 60px`,
		}
	},
	image1: {
		position: "absolute",
		zIndex: -1,
		width: 500,
		height: 500,
		left: 200,
		top: "50%",
		transform: `translateY(-50%)`,
		'@media all and (max-width: 1260px)': {
			left: 120
		},
		'@media all and (max-width: 1190px)': {
			top: "45%",
			width: 400
		},
		'@media all and (max-width: 930px)': {
			left: '50%',
			top: "75%",
			transform: `translate(-50%, -50%)`,
		},
		'@media all and (max-width: 425px)': {
			width: 350
		},
		'@media all and (max-width: 375px)': {
			width: 300
		}
	},
	descriptionContainer: {
		position: "absolute",
		zIndex: -1,
		width: 500,
		right: 250,
		top: "45%",
		transform: `translateY(-50%)`,
		'@media all and (max-width: 1464px)': {
			left: 720
		},
		'@media all and (max-width: 1260px)': {
			left: 650
		},
		'@media all and (max-width: 1190px)': {
			left: 540
		},
		'@media all and (max-width: 1076px)': {
			width: 350,
			top: "50%",
		},
		'@media all and (max-width: 930px)': {
			left: '50%',
			top: "30%",
			transform: `translate(-50%, -50%)`,
		},
		'@media all and (max-width: 425px)': {
			width: 292,
		},
		'@media all and (max-width: 375px)': {
			width: 292,
		},
		'@media all and (max-width: 352px)': {
			width: 260
		}
	},
	descriptionTitle: {
		fontFamily: `Alakamanda`,
		fontWeight: 600,
		'@media all and (max-width: 425px)': {
			fontSize: 40
		},
		'@media all and (max-width: 375px)': {
			fontSize: 35
		}
	},
	description1: {
		paddingTop: 10,
		fontFamily: "Abhaya",
		'@media all and (max-width: 1076px)': {
			width: 300
		},
		'@media all and (max-width: 352px)': {
			width: 250
		}
	}
});

function Questions(props) {
	const classes = useStyles()

	//states
	const [years, setYears] = useState([])
	const [subjects, setSubjects] = useState([])
	const [syllabus, setSyllabus] = useState([])
	const [questions, setQuestions] = useState([])
	const [selectedQuestion, setSelectedQuestion] = useState(0)
	const [isQuestionPaneOpen, setQuestionPaneOpen] = useState(false)
	const [selectedYear, setSelectedYear] = useState('')
	const [selectedSubject, setSelectedSubject] = useState('')
	const [selectedSyllabus, setSelectedSyllabus] = useState('')
	const [wiwarana, setWiwarana] = useState('')
	const [isWiwaranaLoading, setWiwaranaLoading] = useState(false)
	const [isImageVisible, setImageVisible] = useState(true)
	const [progress, setProgress] = React.useState(0)

	//styles for question pane
	const questionStyle = {
		position: 'absolute',
		display: isQuestionPaneOpen ? 'grid' : 'none',
		transform: `translate(-50%, -50%)`,
		cursor: 'default',
		zIndex: 2,
		left: '-50%',
		borderRadius: `0 5px 5px 0`,
		backgroundColor: '#f5f5f5',
		boxShadow: `1px 1px 6px 1px #989898`,
		transition: 'all 0.1s ease',
	}

	//effects
	//select box content
	useEffect(() => {
		//animations
		ScrollReveal().reveal('#topBar', { delay: 200, scale: 0.5 })
		ScrollReveal().reveal('#image1', { delay: 400 })
		ScrollReveal().reveal('#wiwarana-question-description', { delay: 500 })
		ScrollReveal().reveal('#dropdown-container', { delay: 650 })
		ScrollReveal().reveal('#questions-container', { delay: 650 })

		setYears(() => {
			return ['2012', '2013', '2014', '2015', '2016', '2017', '2018', '2019']
		})

		setSubjects(() => {
			/*return ["ගණිතය", "විද්‍යාව", "වාණිජ", "කලා"];*/
			return ['BIO', 'Physics', 'Chemistry', 'Econ', 'Music']
		})

		setSyllabus(() => {
			/*return ["පැරණි", "නව"];*/
			return ['Old', 'New']
		})

		setQuestions([])
		for (let i = 0; i < 50; i++) {
			setQuestions((oldData) => {
				return [...oldData, i + 1]
			});
		}
	}, []);

	//progress bar
	useEffect(() => {
		const timer = setInterval(() => {
			setProgress((oldProgress) => {
				if (oldProgress === 100) {
					return 0;
				}
				const diff = Math.random() * 10;
				return Math.min(oldProgress + diff, 100);
			});
		}, 500);

		return () => {
			clearInterval(timer);
		};
	}, []);

	//question items handler
	function questionItemsHandler(event) {
		setSelectedQuestion(parseInt(event.target.innerText));
		document.getElementById(event.currentTarget.id).style.backgroundColor =
			'#060a21'
		document.getElementById(event.currentTarget.id).style.color = '#ffffff'

		for (let i = 1; i <= 50; i++) {
			if (i !== parseInt(event.currentTarget.id)) {
				document.getElementById(`${i}`).style.backgroundColor = '#d8d8d8'
				document.getElementById(`${i}`).style.color = 'black'
			}
		}

		const timeout = setTimeout(() => {
			document.getElementById('questionPane').style.left = '-50%'
			clearTimeout(timeout)
			const timeout2 = setTimeout(() => {
				setQuestionPaneOpen(false)
				clearTimeout(timeout2)
			}, 105)
		}, 100)
	}

	//questions pane handler
	function questionPaneHandler() {
		if (!isQuestionPaneOpen) {
			setQuestionPaneOpen(true)
			const timeout = setTimeout(() => {
				if (window.matchMedia('(min-width: 542px)').matches) {
					document.getElementById('questionPane').style.left = '54%'
				}
				if (window.matchMedia('(max-width: 542px)').matches) {
					document.getElementById('questionPane').style.left = '30%'
				}
				clearTimeout(timeout)
			}, 100)
		} else {
			const timeout = setTimeout(() => {
				document.getElementById('questionPane').style.left = '-50%'
				clearTimeout(timeout)
				const timeout2 = setTimeout(() => {
					setQuestionPaneOpen(false)
					clearTimeout(timeout2)
				}, 105)
			}, 100)
		}
	}

	//get selected year value
	function yearHandler(event) {
		setSelectedYear(event.target.value);
	}

	//get selected stream value
	function subjectHandler(event) {
		setSelectedSubject(event.target.value);
	}

	//get selected syllabus value
	function syllabusHandler(event) {
		setSelectedSyllabus(event.target.value);
	}

	//search handler
	async function searchHandler() {
		if (
			selectedYear.length > 0 &&
			selectedSubject.length > 0 &&
			selectedSyllabus.length > 0 &&
			selectedQuestion > 0
		) {
			setWiwaranaLoading(true);
			setWiwarana(
				`https://trinet-server.herokuapp.com/wiwarana/?subject=${selectedSubject.toLowerCase()}&year=${selectedYear}&syllubus=${selectedSyllabus.toLowerCase()}&question=${selectedQuestion}`
			);
		} else {
			Swal.fire({
				title: "Error",
				text: "Fields cannot be empty",
				icon: "error",
				confirmButtonColor: "#3f51b5",
			});
		}
	}

	function wiwaranaOnLoading() {
		setWiwaranaLoading(false);
		if (isWiwaranaLoading) {
			setImageVisible(false);
		} else {
			setImageVisible(true);
		}
	}

	//go to home page
	function gotoHomePage() {
		document
			.getElementById('homePage')
			.scrollIntoView({ behavior: "auto" });
	}

	//go to about page
	function gotoAboutPage() {
		document
			.getElementById('aboutPage')
			.scrollIntoView({ behavior: "auto" });
	}

	return (
		<Box className={classes.root} id="questionsPage">
			{isWiwaranaLoading && (
				<LinearProgress variant="determinate" value={progress} />
			)}
			<Box id='topBar' className={classes.topBar}>
				<Typography variant="h3" className={classes.homeTitle}>
					විවරණ
				</Typography>
				<Box className={classes.menuContainer1}>
					<Typography onClick={gotoHomePage} variant="h6" className={classes.menuItem}>
						Home
					</Typography>
					<Typography onClick={gotoAboutPage} variant="h6" className={classes.menuItem}>
						About
					</Typography>
				</Box>
				<Box className={classes.menuContainer2}>
					<Typography variant="h6" className={classes.menuItem}>
						Contact
					</Typography>
					<Typography variant="h6" className={classes.menuItem}>
						Terms & Privacy
					</Typography>
				</Box>
				<Box className={classes.menuContainer3}>
					<Typography onClick={gotoHomePage} variant="h6" className={classes.menuItem}>
						Home
					</Typography>
					<Typography onClick={gotoAboutPage} variant="h6" className={classes.menuItem}>
						About
					</Typography>
					<Typography variant="h6" className={classes.menuItem}>
						Contact
					</Typography>
					<Typography variant="h6" className={classes.menuItem}>
						Terms & Privacy
					</Typography>
				</Box>
				<MenuIcon className={classes.menuIcon} onClick={props.handleDrawerOpen} />
			</Box>
			<Box id='dropdown-container' className={classes.dropDownContainer}>
				<DropDown
					title="වර්ශය"
					items={years}
					itemValue={selectedYear}
					selectHandler={yearHandler}
				/>
				<DropDown
					title="විෂය"
					items={subjects}
					itemValue={selectedSubject}
					selectHandler={subjectHandler}
				/>
				<DropDown
					title="විෂය නිර්දේශය"
					items={syllabus}
					itemValue={selectedSyllabus}
					selectHandler={syllabusHandler}
				/>
				<Button
					variant="contained"
					className={classes.searchButton}
					onClick={searchHandler}
				>
					<SearchIcon style={{ color: '#448dc1', fontSize: 35 }} />
				</Button>
			</Box>
			<Box className={classes.container}>
				<Box id='questions-container' className={classes.questionsContainer}>
					<Box
						className={classes.questionsButton}
						onClick={questionPaneHandler}
					>
						<Typography
							variant="h6"
							className={classes.questionsTitle}
							onClick={questionPaneHandler}
						>
							Select a Question
						</Typography>
						<DonutLargeIcon
							className={classes.questionsIcon}
							onClick={questionPaneHandler}
						/>
					</Box>
					<Box style={questionStyle} className={sty.questionStyle} id='questionPane'>
						{questions.map((question) => {
							return (
								<Box
									className={classes.questionItemParent}
									key={question}
									id={question}
									onClick={questionItemsHandler}
								>
									<Typography
										variant="h6"
										className={classes.questionItemChild}
									>
										{question}
									</Typography>
								</Box>
							);
						})}
					</Box>
				</Box>
				<Box className={classes.wiwaranaContainer}>
					<iframe
						src={wiwarana}
						className={classes.wiwarna}
						id="wiwaranaIframe"
						title="Web"
						onLoad={wiwaranaOnLoading}
					/>
				</Box>
				{isImageVisible && (
					<img id='image1' className={classes.image1} src={image1} alt="image1" />
				)}
				{isImageVisible && (
					<Box id='wiwarana-question-description' className={classes.descriptionContainer}>
						<Typography className={classes.descriptionTitle} variant="h3">
							උසස් පෙළ ළමුන් සඳහා වූ
							<br />
							ඩිජිටල් විවරණ පොත
						</Typography>
						<Typography className={classes.description1} variant="body1">
							විවරණ වෙබ් අඩවිය ප්‍රධාන වශයෙන්ම උසස් පෙළ ළමුන් සදහාම නිර්මාණය කල
							වෙබ් අඩවියකි. මෙමගින් ඕනෑම සිසුවෙකු හට පහසුවෙන්ම ඕනෑම විෂය
							ධාරාවකින් අවශ්‍යය ඕනෑම බහුවරණ ප්‍රශ්නයකට අදාල විවරණයක් කෙටි කාලයක්
							තුල බැලීමට හැක.
						</Typography>
					</Box>
				)}
			</Box>
			<Box className={classes.circle1} />
			<Box className={classes.triangle1} />
		</Box>
	);
}

export default Questions;
