import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import { Box } from '@material-ui/core'
import AOS from 'aos'
import 'aos/dist/aos.css'
import MenuIcon from '@material-ui/icons/Menu'

const useStyles = makeStyles({
    root: {
        flexGrow: 1,
    },
    appBar: {
        backgroundColor: '#ffffff',
        boxShadow: `2px 2px 14px 4px #e6e6e6`,
        border: 'none'
    },
	title: {
		flexGrow: 1,
		fontFamily: `Gurulugomi`,
		fontSize: 40,
		color: 'black',
	},
	menuContainer1: {
        position: 'absolute',
        width: 'max-content',
        display: 'flex',
        gap: 20,
        left: 0,
        right: 0,
        margin: 'auto',
        '@media all and (max-width: 964px)': {
            left: 250,
            right: 'initial',
            margin: 0,
        },
        '@media all and (max-width: 814px)': {
            left: 200,
        },
        '@media all and (max-width: 764px)': {
            left: 175,
        },
        '@media all and (max-width: 690px)': {
            display: 'none',
        }
    },
	menuContainer2: {
        position: 'absolute',
        width: 'max-content',
        display: 'flex',
        gap: 20,
        right: 50,
        '@media all and (max-width: 690px)': {
            display: 'none',
        }
    },
    menuItem: {
        fontFamily: `Poppins, sans-serif`,
        fontSize: 16,
        color: 'black',
        cursor: 'pointer',
        transition: `all 0.1s ease-out`,
        '&:hover': {
            color: '#448dc1',
            transition: `all 0.1s ease-in`,
        }
    },
    menuIcon: {
        display: 'none',
        width: 'max-content',
        cursor: 'pointer',
        color: 'black',
        transition: `all 0.1s ease-out`,
        '@media all and (max-width: 690px)': {
            display: 'block'
        },
        '&:hover': {
            color: '#448dc1',
            transition: `all 0.1s ease-in`
        }
    }
});

function AppBarTop (props) {
    const classes = useStyles()

    //effects
    useEffect(() => {
        AOS.init()
    }, [])

    //go to questions page
    function gotoQuestionsPage () {
        document
			.getElementById('questionsPage')
			.scrollIntoView({behavior: "auto"});
	}

	//go to home page
	function gotoHomePage() {
		document
			.getElementById('homePage')
			.scrollIntoView({behavior: "auto"});
	}

	//go to about page
	function gotoAboutPage() {
		document
			.getElementById('aboutPage')
			.scrollIntoView({behavior: "auto"});
	}

	return (
		<Box className={classes.root}>
			<AppBar data-aos="fade-down" data-aos-offset="400" data-aos-anchor-placement="bottom-center"
					className={classes.appBar} position="fixed" variant='outlined'>
				<Toolbar>
					<Typography variant="h6" className={classes.title}>
						විවරණ
					</Typography>
					<Box className={classes.menuContainer1}>
						<Typography onClick={gotoQuestionsPage} variant="h6" className={classes.menuItem}>
							Questions
						</Typography>
						<Typography onClick={gotoHomePage} variant="h6" className={classes.menuItem}>
							Home
						</Typography>
						<Typography onClick={gotoAboutPage} variant="h6" className={classes.menuItem}>
							About
						</Typography>
					</Box>
                    <Box className={classes.menuContainer2}>
                        <Typography variant="h6" className={classes.menuItem}>
                            Contact
                        </Typography>
                        <Typography variant="h6" className={classes.menuItem}>
                            Terms & Privacy
                        </Typography>
                    </Box>
                    <MenuIcon className={classes.menuIcon} onClick={props.handleDrawerOpen}/>
				</Toolbar>
			</AppBar>
		</Box>
	);
}

export default AppBarTop;