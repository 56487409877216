import React, {useEffect, useState} from "react";
import "./App.css";
import LoaderScreen from "./views/loader-screen/pages/LoaderScreen";
import {Box} from "@material-ui/core";
import Main from "./views/main/pages/Main";

function App() {
  const [state, setState] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setState(true);
    }, 7500);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return <Box className="App">{state ? <Main/> : <LoaderScreen/>}</Box>;
}

export default App;
