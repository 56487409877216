import React, { useEffect } from 'react'
import { Box } from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Typography from '@material-ui/core/Typography'
import image2 from '../../../assets/images/image2.svg'
import image3 from '../../../assets/images/image3.svg'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'
import AOS from 'aos'
import 'aos/dist/aos.css'

const useStyles = makeStyles({
    root: {
        position: 'relative',
        overflow: 'hidden',
        backgroundColor: '#f7f7f7',
    },
    container1: {
        position: 'relative',
        width: 720,
        height: 330,
        left: '50%',
        transform: `translateX(-50%)`,
        marginTop: 70,
        '@media all and (max-width: 816px)': {
            width: 360,
            height: 650,
        },
        '@media all and (max-width: 385px)': {
            width: 330,
        }
    },
    container2: {
        position: "relative",
        width: 830,
        height: 400,
        left: 0,
        right: 0,
        margin: "auto",
        marginTop: 60,
        marginBottom: 30,
        '@media all and (max-width: 816px)': {
            width: 360,
            height: 650,
        },
        '@media all and (max-width: 385px)': {
            width: 330,
        }
    },
    title1: {
        fontFamily: `Davasa`,
        fontSize: 65,
        '@media all and (max-width: 816px)': {
            position: 'absolute',
            width: 345,
        },
        '@media all and (max-width: 425px)': {
            fontSize: 50,
        },
        '@media all and (max-width: 385px)': {
            width: 266,
            left: 0,
            right: 0,
            margin: 'auto'
        }
    },
    title2: {
        position: "absolute",
        fontFamily: "Davasa",
        top: 50,
        right: 105,
        '@media all and (max-width: 816px)': {
            position: 'absolute',
            width: 345,
            top: 10,
            left: 0,
            right: 0,
            margin: 'auto'
        },
        '@media all and (max-width: 425px)': {
            fontSize: 40,
        },
        '@media all and (max-width: 385px)': {
            width: 282,
        }
    },
    descriptionContainer1: {
        width: 350,
        marginTop: 20,
        '@media all and (max-width: 816px)': {
            position: 'absolute',
            top: 150
        },
        '@media all and (max-width: 425px)': {
            top: 115,
        },
        '@media all and (max-width: 385px)': {
            top: 125,
            width: 300,
            left: 0,
            right: 0,
            margin: 'auto'
        }
    },
    descriptionContainer2: {
        position: "absolute",
        top: 150,
        right: 90,
        width: 350,
        marginTop: 20,
        '@media all and (max-width: 816px)': {
            position: 'absolute',
            top: 135,
            left: 0,
            right: 0,
            margin: 'auto'
        },
        '@media all and (max-width: 425px)': {
            top: 115,
        },
        '@media all and (max-width: 385px)': {
            width: 255
        }
    },
    description: {
        fontFamily: `Abhaya`,
    },
    iconContainer: {
        position: 'absolute',
        width: 40,
        height: 40,
        borderRadius: 100,
        backgroundColor: '#F0A529',
        bottom: 70,
        right: 300,
        zIndex: 1,
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#c78825',
        },
        '@media all and (max-width: 816px)': {
            top: 260,
        },
        '@media all and (max-width:425px)': {
            top: 240,
        },
        '@media all and (max-width: 385px)': {
            top: 260,
            left: 50
        },
    },
    icon: {
        position: "absolute",
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        margin: "auto",
    },
    image1: {
        position: "absolute",
        width: 260,
        right: 0,
        top: 0,
        bottom: 0,
        margin: "auto",
        '@media all and (max-width: 816px)': {
            position: 'absolute',
            top: 350,
            left: 0
        }
    },
    image3: {
        position: "absolute",
        width: 400,
        left: 0,
        top: 0,
        bottom: 0,
        margin: "auto",
        '@media all and (max-width: 816px)': {
            position: 'absolute',
            top: 260,
            left: 0
        },
        '@media all and (max-width: 385px)': {
            width: 350,
        }
    },
    square1: {
        position: 'absolute',
        width: 600,
        height: 600,
        right: 0,
        top: -200,
        transform: `rotate(-30deg)`,
        backgroundColor: '#eaf2f7'
    },
    readMore: {
        fontFamily: `Abhaya`,
        fontWeight: 600,
        width: 150,
        textAlign: 'center',
        paddingTop: 5,
        paddingBottom: 5,
        marginTop: 10,
        marginLeft: 130,
        borderRadius: 20,
        backgroundColor: '#F0A529',
        cursor: "pointer",
        "&:hover": {
            backgroundColor: "#c78825",
        },
        '@media all and (max-width: 816px)': {
            position: 'absolute',
            top: 300,
        },
        '@media all and (max-width: 425px)': {
            top: 260,
        },
        '@media all and (max-width: 385px)': {
            top: 290,
            left: 0,
            right: 0,
            margin: 'auto'
        }
    }
});

function Home() {
    const classes = useStyles();

    //go to questions page
    function gotoQuestionsPage() {
        document
            .getElementById('questionsPage')
            .scrollIntoView({ behavior: 'auto' })
    }

    //go to about page
    function gotoAboutPage () {
        document
            .getElementById('aboutPage')
            .scrollIntoView({ behavior: 'auto' })
    }

    useEffect(() => {
        AOS.init()
    }, [])

    return (
        <Box className={classes.root} id="homePage">
            <Box className={classes.square1}/>
            <Box className={classes.container1}>
                <Typography data-aos="fade-zoom-in" data-aos-once data-aos-anchor-placement='top-bottom'
                            data-aos-delay={350} className={classes.title1} variant="h3">
                    මොකක්ද මේ අලුත්ම
                    <br/>
                    විවරණ පොත?
                </Typography>
                <Box data-aos="fade-zoom-in" data-aos-once data-aos-anchor-placement='top-bottom' data-aos-delay={550}
                     className={classes.descriptionContainer1}>
                    <Typography className={classes.description} variant="body1">
                        <b>"විවරණ"</b> යනු අප කණ්ඩායම විසින් නිර්මාණය කළ නවතම මාර්ගගත ඉගෙනුම් පද්ධතියයි.
                        මෙය උසස් පෙළ ළමුන් සඳහාම නිර්මාණ කළ වෙබ් අඩවියක් වන අතර මෙහිදී ඔබට සිංහල
                        මාධ්‍යයෙන් විවරණ අධ්‍යනය කළ හැක.
                    </Typography>
                </Box>
                <img data-aos="slide-left" data-aos-once={true} data-aos-anchor-placement='top-bottom'
                     data-aos-delay={200}
                     className={classes.image1} src={image2} alt="image2"/>
                <Typography data-aos="fade-zoom-in" data-aos-once data-aos-anchor-placement='top-bottom'
                            data-aos-delay={600} className={classes.readMore} variant='body1' onClick={gotoAboutPage}>වැඩිදුර
                    කියවන්න</Typography>
            </Box>
            <Box className={classes.container2}>
                <Typography data-aos="fade-zoom-in" data-aos-once data-aos-anchor-placement='top-bottom'
                            data-aos-delay={350} className={classes.title2} variant="h3">
                    විවරණයක් සොයා ගත හැකි
                    <br/>
                    පහසුම ක්‍රමය
                </Typography>
                <Box data-aos="fade-zoom-in" data-aos-once data-aos-anchor-placement='top-bottom' data-aos-delay={550}
                     className={classes.descriptionContainer2}>
                    <Typography className={classes.description} variant="body1">
                        මෙම වෙබ් අඩවිය මඟින් උසස් පෙළ ළමුන්ට අවශ්‍ය විවරණ පහසුවෙන් හා කඩිනමින් ලබා ගත හැක.ඒ සඳහා ඉහළින්
                        ඇති "විවරණ" පිටුවට යොමු වන්න.
                    </Typography>
                </Box>
                <Box data-aos="fade-zoom-in" data-aos-once data-aos-anchor-placement='top-bottom' data-aos-delay={600}
                     className={classes.iconContainer} onClick={gotoQuestionsPage}>
                    <ArrowUpwardIcon className={classes.icon}/>
                </Box>
                <img data-aos="slide-right" data-aos-once={true} data-aos-anchor-placement='top-bottom'
                     data-aos-delay={200}
                     className={classes.image3} src={image3} alt="image3"/>
            </Box>
        </Box>
    );
}

export default Home;
