import React from "react";
import Spinner from "../../../components/spinner/Spinner";
import {makeStyles} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import ImportContactsIcon from "@material-ui/icons/ImportContacts";
import ProgressBar from "../../../components/progress-bar/ProgressBar";

/*Styles*/
const useStyles = makeStyles({
  root: {
    position: "relative",
    width: "100%",
    height: "100vh",
    backgroundColor: "#000736",
  },
  icon: {
    position: "absolute",
    left: "50%",
    top: "45%",
    transform: `translate(-50%, -50%)`,
    color: "white",
    fontSize: 60,
  },
  title: {
    position: "absolute",
    left: "50%",
    top: "58%",
    transform: `translate(-50%, -50%)`,
    color: "white",
  },
  subTitle: {
    position: "absolute",
    left: "50%",
    top: "65%",
    transform: `translate(-50%, -50%)`,
    color: "white",
  },
});

function LoaderScreen() {
  let classes = useStyles();

  return (
      <div className={classes.root}>
        <Spinner/>
        <ImportContactsIcon className={classes.icon}/>
        <Typography variant="h3" className={classes.title}>
          WELCOME TO WIVARANA
        </Typography>
        <Typography variant="h6" className={classes.subTitle}>
          This is your first Online Learning Platform
        </Typography>
        <ProgressBar/>
      </div>
  );
}

export default LoaderScreen;
