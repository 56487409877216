import React from 'react'
import Questions from '../../questions/pages/Questions'
import Home from '../../home/pages/Home'
import { Box } from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'
import About from '../../about/pages/About'
import Quote from '../../quote/pages/Quote'
import Footer from '../../footer/pages/Footer'
import AppBarTop from '../../../components/app-bar/AppBarTop'
import DrawerMenu from '../../../components/drawer-menu/DrawerMenu'

const useStyles = makeStyles({
    root: {
        overflowX: 'hidden',
    },
})

function Main () {
    const classes = useStyles()

    const [drawerOpen, setDrawerOpen] = React.useState(false)

    const handleDrawerMenuOpen = () => {
        setDrawerOpen(true)
    }

    const handleDrawerMenuClose = () => {
        setDrawerOpen(false)
    }

    return (
        <Box className={classes.root}>
            <AppBarTop handleDrawerOpen={handleDrawerMenuOpen}/>
            <DrawerMenu open={drawerOpen} handleDrawerClose={handleDrawerMenuClose}/>
            <Questions handleDrawerOpen={handleDrawerMenuOpen}/>
            <Home/>
            <About/>
            <Quote/>
            <Footer/>
        </Box>
    );
}

export default Main;
