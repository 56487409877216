import React, { useEffect } from 'react'
import Box from '@material-ui/core/Box'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Typography from '@material-ui/core/Typography'
import AOS from 'aos'
import 'aos/dist/aos.css'

const useStyles = makeStyles({
    root: {
        position: 'relative',
        overflow: 'hidden',
        '@media all and (max-width: 1248px)': {
            width: '100%',
            height: 560
        },
        '@media all and (max-width: 880px)': {
            height: 600
        },
        '@media all and (max-width: 778px)': {
            height: 650
        },
        '@media all and (max-width: 654px)': {
            height: 740
        },
        '@media all and (max-width: 558px)': {
            height: 860
        },
        '@media all and (max-width: 490px)': {
            height: 825
        },
        '@media all and (max-width: 458px)': {
            height: 1040
        },
        '@media all and (max-width: 425px)': {
            height: 1020
        },
        '@media all and (max-width: 354px)': {
            height: 1240
        }
    },
    title: {
        fontFamily: `Davasa`,
        fontSize: 65,
        marginLeft: 100,
        paddingTop: 70,
        '@media all and (max-width: 1248px)': {
            position: 'absolute',
            width: 457,
            left: 0,
            right: 0,
            margin: 'auto'
        },
        '@media all and (max-width: 490px)': {
            fontSize: 50,
            width: 354,
        },
        '@media all and (max-width: 425px)': {
            fontSize: 40,
            width: 282,
        }
    },
    descriptionContainer: {
        width: 800,
        height: 290,
        marginTop: 20,
        marginLeft: 100,
        paddingBottom: 30,
        '@media all and (max-width: 1248px)': {
            position: 'absolute',
            left: 0,
            right: 0,
            margin: 'auto',
            top: 230
        },
        '@media all and (max-width: 880px)': {
            width: 700,
            height: 330
        },
        '@media all and (max-width: 778px)': {
            width: 600,
            height: 380
        },
        '@media all and (max-width: 654px)': {
            width: 500,
            height: 480
        },
        '@media all and (max-width: 558px)': {
            width: 400,
            height: 635
        },
        '@media all and (max-width: 490px)': {
            top: 200
        },
        '@media all and (max-width: 458px)': {
            width: 300,
            height: 850,
        },
        '@media all and (max-width: 425px)': {
            top: 180
        },
        '@media all and (max-width: 354px)': {
            width: 250,
            height: 1070,
        }
    },
    description: {
        fontFamily: `Abhaya`,
    },
    circleContainer: {
        position: 'absolute',
        width: 300,
        height: 360,
        top: 0,
        bottom: 0,
        left: 1000,
        margin: 'auto',
        zIndex: -1,
        '@media all and (max-width: 1296px)': {
            left: 950,
        },
        '@media all and (max-width: 1248px)': {
            left: 0,
            right: 0,
            margin: 'auto',
            opacity: 0.7
        }
    },
    circle1: {
        width: 200,
        height: 200,
        borderRadius: 100,
        backgroundColor: '#efffe7'
    },
    circle2: {
        position: 'absolute',
        width: 150,
        height: 150,
        left: 0,
        bottom: 0,
        borderRadius: 100,
        backgroundColor: '#ffebc6'
    },
    circle3: {
        position: 'absolute',
        width: 100,
        height: 100,
        top: 80,
        bottom: 0,
        right: 40,
        margin: 'auto',
        borderRadius: 100,
        backgroundColor: '#ececec'
    }
});

function About() {
    const classes = useStyles()

    useEffect(() => {
        AOS.init()
    }, [])

    return (
        <Box className={classes.root} id='aboutPage'>
            <Typography data-aos="fade-zoom-in" data-aos-once data-aos-anchor-placement='top-bottom'
                        data-aos-delay={350} className={classes.title} variant="h3">
                විවරණ වෙබ් අඩවිය
                <br/>
                නිර්මාණය කිරීමෙහි අරමුණ
            </Typography>
            <Box data-aos="fade-zoom-in" data-aos-once data-aos-anchor-placement='top-bottom' data-aos-delay={550}
                 className={classes.descriptionContainer}>
                <Typography className={classes.description} variant="body1">
                    වර්ථමානය වන විට සියලුම දෑ ඩිජිටල් කරණය වෙමින් පවතී.අධ්‍යාපනය ද එලෙසම අන්තර්ජාලය ඔස්සේ ඩිජිටල් වෙමින්
                    පවතී.
                    එවැනි පසුබිමක පාසල් සිසුන් හට තම අධ්‍යාපනය කටයුතු ඉදිරියට ගෙන යා හැකි වන්නේ අන්තර්ජාලය
                    හරහාය.විශේෂයෙන්ම
                    අ.පො.ස. උසස් පෙළ ශිෂ්‍යයන් හට තම දෛනික ඉගෙනුම් වැඩ කටයුතු අන්තර්ජාලය හරහා කිරීමට සිදුව
                    තිබේ.වර්ථමානයේ
                    නොයෙකුත් අධ්‍යාපනික වැඩ සටහන්, ඉගෙනුම් පද්ධතීන් (E - Learning Systems) අන්තර්ජාලය තුල දක්නට
                    තිබේ.නමුත්
                    මෙහිදී අප දුටු ප්‍රධානම දෙය වන්නේ උසස් පෙළ සිසුන් හට විවරණ පිළිබඳ අධ්‍යනය සඳහා හරි ප්‍රමිතියකින්
                    යුත් මාර්ගගත (Online)
                    ඉගෙනුම් පද්ධතියක් දක්නට නොතිබීමයි.මෙම හේතුව ප්‍රධාන කොට ගෙන අප විසින් මෙම <b>"විවරණ"</b> නමැති
                    මාර්ගගත ඉගෙනුම් පද්ධතිය
                    නිර්මාණය කලෙමු.මෙය සිංහල මාධ්‍යය ප්‍රධාන කොට ගෙන සැදූ වෙබ් අඩවියකි.එම නිසා මෙම වෙබ් අඩවිය හරහා උසස්
                    පෙළ සිසුන් හට
                    ඉතාමත් පහසුවෙන් විවරණ පිළිබඳ අධ්‍යනය කළහැක.මෙහි අප විසින් ඉදිරිපත් කර ඇත්තේ උසස් පෙළ ගුරුවරුන්
                    විසින් නිර්මාණය කර අප
                    වෙත එවනු ලැබූ විවරණ මූලාශ්‍රයන් වේ.තවද මෙම වෙබ් අඩවිය තුළින් ඕනෑම උසස් පෙළ සිසුවෙකුට කිසිඳු අය
                    කිරීමකින් තොරව විවරණ
                    මූලාශ්‍රයන් අධ්‍යනය කළ හැක.
                    <b>අප මෙම වෙබ් අඩවිය නිර්මාණය කිරීමට මුලිකම හේතුව වන්නේ සෑම උසස් පෙළ
                        සිසුවෙකු/සිසුවියෙකු හටම සමානව පරිපුර්ණ විවරණයක් අධ්‍යයනය කර සාර්ථකව
                        උසස් පෙළ කඩඉම ජයග්‍රහණය කිරීමට රුකුලක් වීම උදෙසාය.</b>
                </Typography>
            </Box>
            <Box data-aos="fade-in" data-aos-once data-aos-anchor-placement='top-bottom' data-aos-delay={300}
                 className={classes.circleContainer}>
                <Box className={classes.circle1}/>
                <Box className={classes.circle2}/>
                <Box className={classes.circle3}/>
            </Box>
        </Box>
    );
}

export default About;