import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import trinetLogo from '../../assets/images/trinet-logo.png'

const useStyles = makeStyles({
    drawer: {
        width: '70%',
        flexShrink: 0
    },
    drawerPaper: {
        width: '70%',
        height: '98%',
        top: 0,
        bottom: 0,
        margin: 'auto',
        borderRadius: `30px 0 0 30px`,
        backgroundColor: '#f8f8f8'
    },
    title: {
        position: 'absolute',
        flexGrow: 1,
        fontFamily: `Gurulugomi`,
        fontSize: 60,
        left: '50%',
        top: 80,
        transform: `translateX(-50%)`,
        color: 'black',
    },
    menuContainer: {
        position: 'absolute',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        rowGap: 20,
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        margin: 'auto'
    },
    menuItem: {
        fontFamily: `Poppins, sans-serif`,
        fontSize: 16,
        color: 'black',
        cursor: 'pointer',
        transition: `all 0.1s ease-out`,
        '&:hover': {
            color: '#448dc1',
            transition: `all 0.1s ease-in`,
        }
    },
    trinetLogo: {
        position: 'absolute',
        width: 150,
        bottom: 50,
        left: '50%',
        transform: `translateX(-50%)`,
        cursor: 'pointer',
        '@media all and (max-width: 376px)': {
            bottom: 70,
        }
    },
    trinetLink: {
        cursor: 'pointer',
        '&:hover': {
            textDecorationLine: 'underline'
        }
    }
})

export default function DrawerMenu (props) {
    const classes = useStyles()

    //go to questions page
    function gotoQuestionsPage () {
        document
            .getElementById('questionsPage')
            .scrollIntoView({ behavior: 'auto' })
        props.handleDrawerClose()
    }

    //go to home page
    function gotoHomePage () {
        document
            .getElementById('homePage')
            .scrollIntoView({ behavior: 'auto' })
        props.handleDrawerClose()
    }

    //go to about page
    function gotoAboutPage () {
        document
            .getElementById('aboutPage')
            .scrollIntoView({ behavior: 'auto' })
        props.handleDrawerClose()
    }

    //navigate to trinet web
    function navigateToTrinet () {
        window.open('https://trinetinnovations.com/')
        props.handleDrawerClose()
    }

    return (
        <div>
            <Drawer
                className={classes.drawer}
                variant="temporary"
                anchor="right"
                open={props.open}
                classes={{
                    paper: classes.drawerPaper,
                }}
                onClose={props.handleDrawerClose}

            >
                <Typography variant="h6" className={classes.title}>
                    විවරණ
                </Typography>
                <Box className={classes.menuContainer}>
                    <Typography onClick={gotoQuestionsPage} variant="h6" className={classes.menuItem}>
                        Questions
                    </Typography>
                    <Typography onClick={gotoHomePage} variant="h6" className={classes.menuItem}>
                        Home
                    </Typography>
                    <Typography onClick={gotoAboutPage} variant="h6" className={classes.menuItem}>
                        About
                    </Typography>
                    <Typography variant="h6" className={classes.menuItem}>
                        Contact
                    </Typography>
                    <Typography variant="h6" className={classes.menuItem}>
                        Terms & Privacy
                    </Typography>
                </Box>
                <img onClick={navigateToTrinet} className={classes.trinetLogo} src={trinetLogo} alt='trinet-logo'/>
            </Drawer>
        </div>
    )
}
