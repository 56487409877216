import React, { useEffect } from 'react'
import { Box } from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'
import image4 from '../../../assets/images/image4.jpg'
import Typography from '@material-ui/core/Typography'
import AOS from 'aos'
import 'aos/dist/aos.css'

const useStyles = makeStyles({
    root: {
        position: 'relative',
        width: '100vw',
        height: 580,
        overflow: 'hidden',
        '@media all and (max-width: 1204px)': {
            height: 900
        },
        '@media all and (max-width: 630px)': {
            height: 720
        },
        '@media all and (max-width: 536px)': {
            height: 690,
        },
        '@media all and (max-width: 482px)': {
            height: 680,
        },
        '@media all and (max-width: 446px)': {
            height: 580,
        },
        '@media all and (max-width: 442px)': {
            height: 560,
        },
        '@media all and (max-width: 348px)': {
            height: 510,
        }
    },
    image: {
        position: 'absolute',
        top: 0,
        background: `center no-repeat`,
        backgroundSize: 'contain',
        left: 100,
        width: 500,
        marginTop: 30,
        '@media all and (max-width: 1314px)': {
            left: 50,
        },
        '@media all and (max-width: 1204px)': {
            top: 370,
            left: 0,
            right: 0,
            margin: 'auto',
        },
        '@media all and (max-width: 630px)': {
            top: 300,
            width: 400,
        },
        '@media all and (max-width: 536px)': {
            top: 270,
        },
        '@media all and (max-width: 482px)': {
            top: 260,
        },
        '@media all and (max-width: 446px)': {
            width: 300,
        },
        '@media all and (max-width: 442px)': {
            top: 240,
        },
        '@media all and (max-width: 344px)': {
            width: 250,
            top: 260,
        },
    },
    quote: {
        position: 'absolute',
        width: 600,
        height: 'max-content',
        top: 0,
        left: 700,
        bottom: 0,
        margin: 'auto',
        fontFamily: `Indeewaree`,
        fontSize: 65,
        '@media all and (max-width: 1314px)': {
            left: 600,
        },
        '@media all and (max-width: 1204px)': {
            width: 565,
            top: 50,
            left: 0,
            right: 0,
            bottom: 'initial',
            margin: 'auto',
        },
        '@media all and (max-width: 630px)': {
            fontSize: 50,
            width: 490
        },
        '@media all and (max-width: 536px)': {
            fontSize: 45,
            width: 442
        },
        '@media all and (max-width: 482px)': {
            fontSize: 40,
            width: 395
        },
        '@media all and (max-width: 442px)': {
            fontSize: 35,
            width: 345
        },
        '@media all and (max-width: 386px)': {
            width: 305
        },
        '@media all and (max-width: 344px)': {
            width: 280,
            textAlign: 'center'
        }
    },
    author: {
        position: 'absolute',
        width: "max-content",
        height: 'max-content',
        top: 280,
        left: 700,
        bottom: 0,
        margin: 'auto',
        fontFamily: `Montserrat, sans-serif`,
        '@media all and (max-width: 1314px)': {
            left: 600,
        },
        '@media all and (max-width: 1204px)': {
            top: 300,
            bottom: 'initial',
            left: 0,
            right: 0,
            margin: 'auto',
        },
        '@media all and (max-width: 630px)': {
            top: 240,
        },
        '@media all and (max-width: 536px)': {
            top: 220,
            fontSize: 18
        },
        '@media all and (max-width: 482px)': {
            top: 210,
        },
        '@media all and (max-width: 442px)': {
            top: 190,
            fontSize: 15
        },
        '@media all and (max-width: 344px)': {
            top: 215,
            fontSize: 14
        }
    }
});

function Quote() {
    const classes = useStyles()

    useEffect(() => {
        AOS.init()
    }, [])
    return (
        <Box className={classes.root}>
            <img data-aos="zoom-in-right" data-aos-once={true} data-aos-anchor-placement='top-bottom'
                 data-aos-delay={200}
                 src={image4} alt='image4' className={classes.image}/>
            <Typography data-aos="fade-zoom-in" data-aos-once data-aos-anchor-placement='top-bottom'
                        data-aos-delay={500} className={classes.quote} variant="h3">
                “අධ්‍යාපනය යනු හුදෙක් කරුණු ඉගෙන ගැනීම පමණක් නොව, සිතීම සඳහා සිත පුහුණු කිරීමයි.”
            </Typography>
            <Typography data-aos="fade-zoom-in" data-aos-once data-aos-anchor-placement='top-bottom'
                        data-aos-delay={550} className={classes.author} variant="h6">
                - Albert Einstein -
            </Typography>
        </Box>
    );
}

export default Quote;