import React from "react";
import {makeStyles, useTheme} from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

/*Styles*/
const useStyles = makeStyles((theme) => ({
  formControl: {
    position: "relative",
    margin: theme.spacing(1),
    minWidth: 120,
  },
  select: {
    width: 150,
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(item, itemName, theme) {
  return {
    fontWeight:
        itemName.indexOf(item) === -1
            ? theme.typography.fontWeightRegular
            : theme.typography.fontWeightMedium,
  };
}

function DropDown(props) {
  const classes = useStyles();
  const theme = useTheme();

  return (
      <div>
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel id="demo-simple-select-outlined-label">
            {props.title}
          </InputLabel>
          <Select
              labelId="demo-simple-select-outlined-label"
              id="hello"
              value={props.itemValue}
              onChange={props.selectHandler}
              label={props.title}
              MenuProps={MenuProps}
              className={classes.select}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {props.items.map((item) => (
                <MenuItem
                    key={item}
                    value={item}
                    style={getStyles(item, props.itemValue, theme)}
                >
                  {item}
                </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
  );
}

export default DropDown;
