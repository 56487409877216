import React from 'react'
import { Box } from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'
import wave from '../../../assets/images/wave.png'
import trinetLogo from '../../../assets/images/trinet-logo.png'
import FacebookIcon from '@material-ui/icons/Facebook'
import TwitterIcon from '@material-ui/icons/Twitter'
import LinkedInIcon from '@material-ui/icons/LinkedIn'
import Typography from '@material-ui/core/Typography'
import EmailIcon from '@material-ui/icons/Email'

const useStyles = makeStyles({
    root: {
        position: 'relative',
        width: '100vw',
        height: 450,
        '@media all and (max-width: 1000px)': {
            height: 480
        },
        '@media all and (max-width: 584px)': {
			height: 600,
			backgroundColor: '#505050'
		},
		'@media all and (max-width: 404px)': {
			height: 610,
		}
	},
	image: {
		position: "absolute",
		width: "100%",
		height: '100%',
		zIndex: -1,
		'@media all and (max-width: 584px)': {
			display: 'none',
		}
	},
	content: {
		position: "absolute",
		display: "flex",
		flexDirection: 'column',
		justifyContent: "center",
		alignItems: "flex-start",
		gap: 5,
		width: 'max-content',
		height: "max-content",
		top: 200,
		left: 230,
		'@media all and (max-width: 1300px)': {
			left: 120
		},
		'@media all and (max-width: 1000px)': {
			left: 50
		},
		'@media all and (max-width: 854px)': {
			top: 50
		}
	},
	title: {
		fontFamily: `Gurulugomi`,
		fontSize: 60,
		color: 'white'
	},
	emailContainer: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		gap: 10
	},
	emailIcon: {
		fontSize: 16,
		color: '#448dc1'
	},
	email: {
		fontSize: 16,
		fontFamily: `Roboto, sans-serif`,
		fontWeight: 400,
		color: '#909090',
	},
	quickLinksContainer: {
		position: "absolute",
		display: "flex",
		flexDirection: 'column',
		justifyContent: "center",
		alignItems: "flex-start",
		gap: 10,
		width: 'max-content',
		height: "max-content",
		top: 200,
		left: '42%',
		transform: `translateX(-50%)`,
		'@media all and (max-width: 1300px)': {
			left: '40%'
		},
		'@media all and (max-width: 1000px)': {
			left: '38%'
		},
		'@media all and (max-width: 854px)': {
			left: 100,
			transform: `translateX(0)`,
		},
		'@media all and (max-width: 678px)': {
			left: 60,
		},
		'@media all and (max-width: 584px)': {
			left: '25%'
		},
		'@media all and (max-width: 425px)': {
			left: '15%'
		}
	},
	quickLinks: {
		color: 'white',
		fontWeight: 500,
		fontSize: 18,
		fontFamily: `Roboto, sans-serif`,
		paddingBottom: 15,
	},
	menuItem: {
		fontSize: 16,
		fontFamily: `Roboto, sans-serif`,
		fontWeight: 400,
		color: '#909090',
		cursor: 'pointer',
		transition: 'all 0.1s ease-out',
		'&:hover': {
			color: '#448dc1',
			transform: `scale(1.1)`,
			transition: 'all 0.1s ease-in',
		}
	},
	supportContainer: {
		position: "absolute",
		display: "flex",
		flexDirection: 'column',
		justifyContent: "center",
		alignItems: "flex-start",
		gap: 10,
		width: 'max-content',
		height: "max-content",
		top: 200,
		left: '60%',
		transform: `translateX(-50%)`,
		'@media all and (max-width: 1300px)': {
			left: '62%'
		},
		'@media all and (max-width: 1000px)': {
			left: '60%'
		},
		'@media all and (max-width: 854px)': {
			left: '50%'
		},
		'@media all and (max-width: 584px)': {
			left: '65%'
		},
		'@media all and (max-width: 425px)': {
			left: '75%'
		}
	},
	support: {
		color: 'white',
		fontWeight: 500,
		fontSize: 18,
		fontFamily: `Roboto, sans-serif`,
		paddingBottom: 15,
	},
	supportItem: {
		fontSize: 16,
		fontFamily: `Roboto, sans-serif`,
		fontWeight: 400,
		color: '#909090',
		cursor: 'pointer',
		transition: 'all 0.1s ease-out',
		'&:hover': {
			color: '#448dc1',
			transform: `scale(1.1)`,
			transition: 'all 0.1s ease-in',
		}
	},
	socialMediaContainer: {
		position: "absolute",
		display: "flex",
		justifyContent: "center",
		alignItems: "flex-start",
		flexDirection: 'column',
		gap: 15,
		height: "max-content",
		right: 230,
		top: 200,
		'@media all and (max-width: 1300px)': {
			right: 120
		},
		'@media all and (max-width: 1000px)': {
			right: 50
		},
		'@media all and (max-width: 854px)': {
			right: 100,
		},
		'@media all and (max-width: 678px)': {
			right: 60,
		},
		'@media all and (max-width: 584px)': {
			top: 380,
			left: '50%',
			transform: `translateX(-50%)`,
			marginLeft: 'auto',
			alignItems: "center",
		}
	},
	social: {
		color: 'white',
		fontWeight: 500,
		fontSize: 18,
		fontFamily: `Roboto, sans-serif`,
	},
	circleContainer: {
		position: 'relative',
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		gap: 10,
	},
	circle: {
		position: "relative",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		width: 40,
		height: 40,
		borderRadius: 100,
		backgroundColor: "white",
		cursor: 'pointer',
		transition: 'all 0.1s ease-out',
		'&:hover': {
			backgroundColor: "#448dc1",
			color: 'white',
			transition: 'all 0.1s ease-in',
		}
	},
	icon: {
		fontSize: 25,
		cursor: 'pointer'
	},
	copyRight: {
        position: 'absolute',
        color: '#909090',
        width: 'max-content',
        fontFamily: `Roboto, sans-serif`,
        bottom: 5,
        left: '50%',
        transform: 'translateX(-50%)',
        '@media all and (max-width: 470px)': {
            fontSize: 14,
        },
        '@media all and (max-width: 404px)': {
            width: 250,
            textAlign: 'center'
        }
    },
	trinetLogo: {
		position: "absolute",
		width: 200,
		right: 175,
		top: 320,
		cursor: 'pointer',
		'@media all and (max-width: 1300px)': {
			right: 60
		},
		'@media all and (max-width: 1000px)': {
			top: 'initial',
			bottom: 40,
			right: 'initial',
			left: '50%',
			transform: `translateX(-50%)`
		},
		'@media all and (max-width: 404px)': {
			bottom: 60,
		}
	},
	trinetLink: {
		cursor: 'pointer',
		'&:hover': {
			textDecorationLine: 'underline'
		}
	}
});

function Footer() {
	const classes = useStyles();

	//go to questions page
	function gotoQuestionsPage() {
		document
			.getElementById('questionsPage')
			.scrollIntoView({behavior: "auto"});
	}

	//go to home page
	function gotoHomePage() {
		document
			.getElementById('homePage')
			.scrollIntoView({behavior: "auto"});
	}

	//go to about page
	function gotoAboutPage() {
		document
			.getElementById('aboutPage')
			.scrollIntoView({behavior: "auto"});
	}

	//navigate to trinet web
	function navigateToTrinet() {
		window.open('https://trinetinnovations.com/');
	}

	return (
		<Box className={classes.root}>
			<img className={classes.image} src={wave} alt="image5"/>
			<Box className={classes.content}>
				<Typography className={classes.title} variant='h3'>විවරණ</Typography>
				<Box className={classes.emailContainer}>
					<EmailIcon className={classes.emailIcon}/>
					<Typography className={classes.email} variant='h6'>info@wiwarana.com</Typography>
				</Box>
			</Box>
			<Box className={classes.quickLinksContainer}>
				<Typography className={classes.quickLinks} variant='h6'>Quicklinks</Typography>
				<Typography onClick={gotoQuestionsPage} variant='h6' className={classes.menuItem}>Questions</Typography>
				<Typography onClick={gotoHomePage} variant='h6' className={classes.menuItem}>Home</Typography>
				<Typography onClick={gotoAboutPage} variant='h6' className={classes.menuItem}>About</Typography>
			</Box>
			<Box className={classes.supportContainer}>
				<Typography className={classes.support} variant='h6'>Support</Typography>
				<Typography variant='h6' className={classes.supportItem}>Contact</Typography>
				<Typography variant='h6' className={classes.supportItem}>Terms & Privacy</Typography>
			</Box>
			<Box className={classes.socialMediaContainer}>
				<Typography className={classes.social} variant='h6'>Social</Typography>
				<Box className={classes.circleContainer}>
					<Box className={classes.circle}>
						<FacebookIcon className={classes.icon}/>
					</Box>
					<Box className={classes.circle}>
						<TwitterIcon className={classes.icon}/>
					</Box>
					<Box className={classes.circle}>
						<LinkedInIcon className={classes.icon}/>
					</Box>
				</Box>
			</Box>
			<Typography className={classes.copyRight}>Copyright © 2021 | All Right Reserved By <span
				className={classes.trinetLink} onClick={navigateToTrinet}>Trinet Innovations</span></Typography>
			<img onClick={navigateToTrinet} className={classes.trinetLogo} src={trinetLogo} alt='trinet-logo'/>
		</Box>
	);
}

export default Footer;
